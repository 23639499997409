#root {
  height: 100%;
  width: 100%;
  position: fixed;
}

@font-face {
  font-family: 'Freight';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FreightTextProLight-Regular.otf');
}

@font-face {
  font-family: 'Freight';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/FreightTextProLight-Italic.otf');
}

@font-face {
  font-family: 'Freight';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/FreightTextProMedium-Regular.otf');
}


@font-face {
  font-family: 'Founders Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FoundersGrotesk-Regular.otf');
}

@font-face {
  font-family: 'Founders Grotesk';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/FoundersGrotesk-Light.otf');
}
